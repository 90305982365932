var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import './error-block.scss';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
var ErrorBlock = /** @class */ (function (_super) {
    __extends(ErrorBlock, _super);
    function ErrorBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorBlock.prototype.render = function () {
        var _a = this.props, title = _a.title, subtitle = _a.subtitle, text = _a.text, btntext = _a.btntext, href = _a.href;
        return (React.createElement("div", { className: "error-block" },
            React.createElement("div", { className: "error-block__title" }, title),
            React.createElement("div", { className: "error-block__subtitle" }, subtitle),
            React.createElement("div", { className: "error-block__text" }, text),
            React.createElement("div", { className: "error-block__button" },
                React.createElement(Link, { to: href }, btntext))));
    };
    ErrorBlock = __decorate([
        withRouter
    ], ErrorBlock);
    return ErrorBlock;
}(React.Component));
export default ErrorBlock;
