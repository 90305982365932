var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ErrorBlock from '../error-block';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            hasAppError: false
        };
        _this.refresh = function () {
            location.reload();
        };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function () {
        this.setState({ hasAppError: true });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasAppError) {
            return (React.createElement(ErrorBlock, { title: "Oops", subtitle: "Something went wrong", text: "", href: "/", btntext: "Refresh page", onClick: this.refresh }));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
