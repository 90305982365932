var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Helmet } from 'react-helmet';
import NotFound from './not-found';
export default (function (props) { return [
    React.createElement(Helmet, { key: "NotFoundPageHelmet" },
        React.createElement("title", null, "Not Found"),
        React.createElement("meta", { name: "description", content: "Not Found" })),
    React.createElement(NotFound, __assign({ key: "NotFoundPageComponent" }, props))
]; });
