var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Switch, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import Layout from '../components/layout/index';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
// TODO review external
import '../assets/css/animate.min.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/magnific-popup.css';
import '../assets/css/meanmenu.css';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/slick.css';
import '../assets/css/themify-icons.css';
import '../assets/css/main.scss';
import '../assets/css/responsive.css';
import './page-transitions.scss';
import './app.scss';
import ErrorBoundary from '../components/error-boundary/index';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            window.scrollTo(0, 0);
        }
    };
    App.prototype.render = function () {
        var _a = this.props, location = _a.location, children = _a.children;
        var currentKey = location.pathname.split('/')[1] || '/';
        return (React.createElement(ErrorBoundary, null,
            React.createElement(Layout, { className: "page page-" + currentKey },
                React.createElement(TransitionGroup, { component: "main", className: "page-main" },
                    React.createElement(CSSTransition, { key: currentKey, timeout: { enter: 500, exit: 0 }, classNames: "fade" },
                        React.createElement(Switch, { location: location }, children))))));
    };
    App = __decorate([
        withRouter,
        observer
    ], App);
    return App;
}(React.Component));
export default App;
