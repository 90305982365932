import PageHome from './pages/home';
// import PageProduct from './pages/product'
// import PageMobile from './pages/mobile'
// import PageControl from './pages/control'
// import PageFaq from './pages/faq'
// import PageInvestor from './pages/investor'
//import PageInvestFaq from './pages/investfaq'
// import PageMap from './pages/map'
// import PageContact from './pages/contact'
// import PageAboutUs from './pages/about-us'
// import PageApp from './pages/app'
// import PageSupport from './pages/support'
// import PageInstruction from './pages/instruction'
// import PageOferta from './pages/oferta'
// import PagePrivacy from './pages/privacy'
// import PagePrivacyKometa from './pages/privacy-kometa'
// import PagePrivacyVesev from './pages/privacy-vesev'
// import PageAgreement from './pages/agreement'
// import PageChargeRules from './pages/charge-rules'
// import PageConfidential from './pages/confidential'
// import PageEmulator from './pages/simulator'
// import PageRemoveAccount from './pages/remove-account/remove-account'
// import PageIskraRemoveAccount from './pages/remove-account/iskra-remove-account'
// import PageTrassaRemoveAccount from './pages/remove-account/trassa-remove-account'
// import PageSparkRemoveAccount from './pages/remove-account/spark-remove-account'
// import PageLpenergyRemoveAccount from './pages/remove-account/lpenergy-remove-account'
// import PageZaryadkarfRemoveAccount from './pages/remove-account/zaryadkarf-remove-account'
// import PageElectrocarsrusRemoveAccount from './pages/remove-account/electrocarsrus-remove-account'
// import PageZaryadkabyRemoveAccount from './pages/remove-account/zaryadkaby-remove-account'
// import PageKometaRemoveAccount from './pages/remove-account/kometa-remove-account'
// import PageVesevRemoveAccount from './pages/remove-account/vesev-remove-account'
import NotFoundScreen from './pages/not-found';
import PageDeskription from './pages/description';
import PageApi from './pages/api';
import PageInstructions from './pages/instructions';
// not used page, remove if needed
// import PageNews from './pages/not-used/news'
// import PageNewsD3M12E20 from './pages/not-used/news-pages/news-3-12-20'
// import PageInvestorOld from './pages/not-used/investor-old'
var routes = [
    {
        path: '/',
        exact: true,
        component: PageHome
    },
    // {
    //   path: '/product/',
    //   exact: true,
    //   component: PageProduct
    // },
    // {
    //   path: '/product/mobile/',
    //   exact: true,
    //   component: PageMobile
    // },
    // {
    //   path: '/investor/',
    //   exact: true,
    //   component: PageInvestor
    // },
    // {
    //   path: '/investor/faq/',
    //   exact: true,
    //   component: PageInvestFaq
    // },
    // {
    //   path: '/map/',
    //   exact: true,
    //   component: PageMap
    // },
    // {
    //   path: '/contacts/',
    //   exact: true,
    //   component: PageContact
    // },
    // {
    //   path: '/about-us/',
    //   exact: true,
    //   component: PageAboutUs
    // },
    // {
    //   path: '/app/',
    //   exact: true,
    //   component: PageApp
    // },
    // {
    //   path: '/support/',
    //   exact: true,
    //   component: PageSupport
    // },
    // {
    //   path: '/instruction/',
    //   exact: true,
    //   component: PageInstruction
    // },
    // {
    //   path: '/oferta/',
    //   exact: true,
    //   component: PageOferta
    // },
    // {
    //   path: '/privacy/',
    //   exact: true,
    //   component: PagePrivacy
    // },
    // {
    //   path: '/agreement/',
    //   exact: true,
    //   component: PageAgreement
    // },
    // {
    //   path: '/charge-rules/',
    //   exact: true,
    //   component: PageChargeRules
    // },
    // {
    //   path: '/confidential/',
    //   exact: true,
    //   component: PageConfidential
    // },
    //  {
    //    path: '/ocpp/simulator/',
    //    exact: true,
    //    component: PageEmulator
    //  },
    // {
    //   path: '/apps/remove-account/',
    //   exact: true,
    //   component: PageRemoveAccount
    // },
    // {
    //   path: '/apps/iskra/remove-account/',
    //   exact: true,
    //   component: PageIskraRemoveAccount
    // },
    // {
    //   path: '/apps/trassa/remove-account/',
    //   exact: true,
    //   component: PageTrassaRemoveAccount
    // },
    // {
    //   path: '/apps/spark/remove-account/',
    //   exact: true,
    //   component: PageSparkRemoveAccount
    // },
    // {
    //   path: '/apps/lpenergy/remove-account/',
    //   exact: true,
    //   component: PageLpenergyRemoveAccount
    // },
    // {
    //   path: '/apps/zaryadkarf/remove-account/',
    //   exact: true,
    //   component: PageZaryadkarfRemoveAccount
    // },
    {
        path: '/description/',
        exact: true,
        component: PageDeskription
    },
    {
        path: '/api/',
        exact: true,
        component: PageApi
    },
    {
        path: '/instructions/',
        exact: true,
        component: PageInstructions
    },
    // {
    //   path: '/apps/electrocarsrus/remove-account/',
    //   exact: true,
    //   component: PageElectrocarsrusRemoveAccount
    // },
    // {
    //   path: '/apps/zaryadkaby/remove-account/',
    //   exact: true,
    //   component: PageZaryadkabyRemoveAccount
    // },
    // {
    //   path: '/apps/kometa/remove-account/',
    //   exact: true,
    //   component: PageKometaRemoveAccount
    // },
    // {
    //   path: '/apps/kometa/privacy/',
    //   exact: true,
    //   component: PagePrivacyKometa
    // },
    // {
    //   path: '/apps/vesev/remove-account/',
    //   exact: true,
    //   component: PageVesevRemoveAccount
    // },
    // {
    //   path: '/apps/vesev/privacy/',
    //   exact: true,
    //   component: PagePrivacyVesev
    // },
    {
        exact: true,
        component: NotFoundScreen,
        status: 404
    }
    // not used page, remove if needed
    // {
    //   path: '/news/',
    //   exact: true,
    //   component: PageNews
    // },
    // {
    //   path: '/news3_12_20/',
    //   exact: true,
    //   component: PageNewsD3M12E20
    // },
    // {
    //   path: '/investor-old/',
    //   exact: true,
    //   component: PageInvestorOld
    // },
];
export default routes;
