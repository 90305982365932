var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var Api = /** @class */ (function (_super) {
    __extends(Api, _super);
    function Api() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Api.prototype.render = function () {
        return (React.createElement("main", null,
            React.createElement("div", { className: "container pb-100 pt-100" },
                React.createElement("h2", { className: "section-title text-center pb-5 " }, "\u0422\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 API \u041F\u041E \u00AB\u0421\u0438\u0441\u0442\u0435\u043C\u0430 \u0443\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F \u0437\u0430\u0440\u044F\u0434\u043D\u044B\u043C\u0438 \u0441\u0442\u0430\u043D\u0446\u0438\u044F\u043C\u0438 \u0434\u043B\u044F \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043C\u043E\u0431\u0438\u043B\u0435\u0439\u00BB"),
                React.createElement("table", { width: "100%" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\change-availability.ts")))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId \u2013 ID \u0417\u0421,",
                                    React.createElement("br", null),
                                    "\u0442ype \u2013 (\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439)",
                                    React.createElement("br", null),
                                    "connectorId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430 (\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439)")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\ntype: OCPP160.datatypes.AvailabilityTypeEnumType\nevseId?: number\nconnectorId?: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" }, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Error\"."),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n res.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E result, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"ChangeAvailabilityRequest rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresult || {\nerror: true,\nmessage: 'ChangeAvailabilityRequest rejected',"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, " \u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Not implemented\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented', })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"ChangeAvailabilityRequest\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'ChangeAvailabilityRequest rejected'} )"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\change-configuration.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                React.createElement("br", null),
                                "Key \u2013 \u0438\u043D\u0434\u0438\u0432\u0438\u0434\u0443\u0430\u043B\u044C\u043D\u044B\u0439 \u043A\u043B\u044E\u0447",
                                React.createElement("br", null),
                                "Value - \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 ",
                                React.createElement("br", null),
                                React.createElement("p", null, "chargingStationId \u2013 ID \u0417\u0421 ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, " key: string\n  value: string\n  chargingStationId: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0417\u0430\u043F\u0438\u0441\u044B\u0432\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435 \"Websocket connection not found\", \u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0435\u043C \u043E\u0442\u0432\u0435\u0442 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0417\u0430\u043F\u0438\u0441\u044B\u0432\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435 \"ChargingStation not found\" \u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)\n}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \"Not implemented\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"changeConfigurationResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'changeConfigurationResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 : \"changeConfigurationResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'changeConfigurationResponse rejected'}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\clear-cache.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439 chargingStationId \u2013 ID \u0417\u0421")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Not implemented\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"changeConfigurationResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'changeConfigurationResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"changeConfigurationResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'changeConfigurationResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    ' ',
                                    React.createElement("strong", null, "src\\routes\\ocpp\\disconnect.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421,",
                                    React.createElement("br", null),
                                    "\u0421onnectionId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "disconnectedReason(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043F\u0440\u0438\u0447\u0438\u043D\u044B \u0440\u0430\u0437\u0440\u044B\u0432\u0430 \u0441\u0432\u044F\u0437\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nconnectionId?: string\ndisconnectedReason?: db.datatypes.MonitoringDisconnectedReasonEnum"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0441\u043E\u0431\u044B\u0442\u0438\u0435 \"Connection not found\" \u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435: \"ActiveConnections not found\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Connection not found', { chargingStationId })\nres.send('ActiveConnections not found')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n \tres.sendStatus(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0442\u0432\u0435\u0442 \"\u043E\u043A\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: false,\nstatus: 'ok',})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0442\u0432\u0435\u0442 \"\u043E\u043A\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: false,\nstatus: 'ok',\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\get-configuration.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u043F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 'Not implemented'.")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"changeConfigurationResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'changeConfigurationResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443 \"GetConfigurationRequest rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'GetConfigurationRequest rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\get-diagnostics.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u043F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 chargingStationId,",
                                    React.createElement("br", null),
                                    "startTime \u0412\u0440\u0435\u043C\u044F \u043D\u0430\u0447\u0430\u043B\u0430 \u0437\u0430\u0440\u044F\u0434\u043A\u0438,",
                                    React.createElement("br", null),
                                    "stopTime \u0412\u0440\u0435\u043C\u044F \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F \u0437\u0430\u0440\u044F\u0434\u043A\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nstartTime: string\nstopTime: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 'ChargingStation not found' \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 'Not implemented'.")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043B\u0438\u0431\u043E response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438, \u043B\u0438\u0431\u043E \u043E\u0448\u0438\u0431\u043A\u0443")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\nresponse || {\nerror: true,\nmessage: 'GetDiagnosticsRequest rejected',})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\reset.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u043F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 ",
                                    React.createElement("br", null),
                                    "type \u2013 \u0442\u0438\u043F reset")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\ntype: OCPP160.datatypes.ResetTypeEnumType"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412 \u0441\u043B\u0443\u0447\u0430\u0435 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0438\u044F \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u044F \u0441 \u0417\u0421 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u0442\u0441\u044F \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Connection not found', { chargingStationId })\nres.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n\tres.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 response. ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(response)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 response.")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(response)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \"Not implemented\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented',})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(response)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\start-transaction\\index.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438.",
                                    React.createElement("br", null),
                                    "evseId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 EVSE",
                                    React.createElement("br", null),
                                    "connectorId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430,",
                                    React.createElement("br", null),
                                    "customerId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043B\u0438\u0435\u043D\u0442\u0430",
                                    React.createElement("br", null),
                                    "companyId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438",
                                    React.createElement("br", null),
                                    "userId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F, ",
                                    React.createElement("br", null),
                                    "app: \u0442\u0438\u043F \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F, \u0438\u0437 \u043A\u043E\u0442\u043E\u0440\u043E\u0433\u043E \u0438\u043D\u0438\u0446\u0438\u0438\u0440\u043E\u0432\u0430\u043D \u0437\u0430\u043F\u0440\u043E\u0441 ",
                                    React.createElement("br", null),
                                    "appVersion: \u0432\u0435\u0440\u0441\u0438\u044F \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F.",
                                    React.createElement("br", null),
                                    "roamingAccessId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u043A \u0440\u043E\u0443\u043C\u0438\u043D\u0433\u0443")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nevseId: number\nconnectorId: number\ncustomerId: string\ncompanyId: string\nuserId: string\napp: db.datatypes.AppTypeEnum\nappVersion: string\nroamingAccessId: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('connection not found', { chargingStationId })\nres.sendStatus(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\"\u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \"Can't find current connector\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: Can't find current connector"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"400\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.sendStatus(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \"Transaction initiating on current connector\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage:Transaction initiating on current connector'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u0437\u0430\u043F\u0438\u0441\u044C 'No customerId or userId', \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u0441\u0442\u0430\u0442\u0443\u0441 \"400\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('No customerId or userId', { chargingStationId })\nres.sendStatus(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C remoteStartId")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: false,\nremoteStartId})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \"Remote Start Transaction Response rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Remote Start Transaction Response rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \"Not implemented\" ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \"Remote Start Transaction Response has error\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Remote Start Transaction Response has error'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \"Start Transaction Response rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Start Transaction Response rejected' })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\stop-transaction.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "transactionId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0442\u0440\u0430\u043D\u0437\u0430\u043A\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "remoteStartId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0443\u0434\u0430\u043B\u0451\u043D\u043D\u043E\u0433\u043E \u0437\u0430\u043F\u0443\u0441\u043A\u0430 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0430 \u0437\u0430\u0440\u044F\u0434\u043A\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "transactionId?: string\nremoteStartId?: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u0442\u0435\u043A\u0441\u0442 \u043E\u0448\u0438\u0431\u043A\u0438 'Transaction not found' \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Transaction not found', { id: transactionId })\nres.send Status(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u0437\u0430\u043F\u0438\u0441\u044C \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435 'Current connector not found in transaction' \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0432 \u043E\u0442\u0432\u0435\u0442 \u0441\u0442\u0430\u0442\u0443\u0441 \"400\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Current connector not found in transaction', { transaction })\nres.send Status(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\unlock-connector.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "evseId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 EVSE ",
                                    React.createElement("br", null),
                                    "connectorId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nevseId: number\nconnectorId: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\n res.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.send Status(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(response)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C response \u043E\u0442 \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nstatus:\nresponse response.ack === 'null'\n? UNLOCKED\n: UNLOCK_FAILED})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Not implemented\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Not implemented',})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"UnlockConnectorRequest rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'UnlockConnectorRequest rejected',})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \"unlockConnectorResponse\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(unlockConnectorResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\cancel-reservation.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "reservationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0440\u0435\u0437\u0435\u0440\u0432\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nreservationId: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    ' ',
                                    "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.send Status(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"cancelReservationResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'cancelReservationResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\clear-charging-profile.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "id(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - - id (\u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440) \u0437\u0430\u0440\u044F\u0434\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "connectorId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430",
                                    React.createElement("br", null),
                                    "chargingProfilePurpose(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0446\u0435\u043B\u044C \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0444\u0438\u043B\u044F (\u043F\u0440\u0438 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u0438 \u0431\u0430\u043B\u0430\u043D\u0441\u0438\u0440\u043E\u0432\u043A\u0438 \u043D\u0430\u0433\u0440\u0443\u0437\u043A\u0438) ",
                                    React.createElement("br", null),
                                    "stackLevel(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0443\u0440\u043E\u0432\u0435\u043D\u044C \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u0438\u044F \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F \u0432 \u0441\u0442\u0435\u043A\u0435 \u0438\u0435\u0440\u0430\u0440\u0445\u0438\u0438 \u0437\u0430\u0440\u044F\u0434\u043D\u044B\u0445 \u043F\u0440\u043E\u0444\u0438\u043B\u0435\u0439.")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\n        id?: number\n        connectorId?: number\n        chargingProfilePurpose?: OCPP160.datatypes.ChargingProfilePurposeTypeEnumType\n        stackLevel?: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\n        res.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441\"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n        res.send Status(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"clearChargingProfileResponse\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        message: 'clearChargingProfileResponse rejected',"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C clearChargingProfileResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(clearChargingProfileResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    ' ',
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\data-transfer.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 ",
                                    React.createElement("br", null),
                                    "vendorId - \u0438\u043C\u044F \u0432\u0435\u043D\u0434\u043E\u0440\u0430, ",
                                    React.createElement("br", null),
                                    "messageId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "data \u2013 \u0434\u0430\u043D\u043D\u044B\u0435",
                                    ' ')),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\n        vendorId: string\n        messageId: string\n        data: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\n        res.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\"\u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n        res.send Status(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"DataTransferRequest rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        message: 'DataTransferRequest rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 dataTransferResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(dataTransferResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\get-composite-schedule.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "id(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 id (\u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440) \u0437\u0430\u0440\u044F\u0434\u043E\u0439 \u0441\u0442\u0430\u043D\u0446\u0438\u0438 ",
                                    React.createElement("br", null),
                                    "duration \u2013 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0437\u0430\u0440\u044F\u0434\u043A\u0438",
                                    React.createElement("br", null),
                                    "connectorId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430,",
                                    React.createElement("br", null),
                                    "chargingRateUnit \u2013 \u0441\u043A\u043E\u0440\u043E\u0441\u0442\u044C \u0437\u0430\u0440\u044F\u0434\u043A\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\n        id?: number\n        connectorId?: number\n        duration: number\n        chargingRateUnit?: OCPP160.datatypes.ChargingRateUnitTypeEnumType\n        }"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\n        res.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n        res.send Status(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"getCompositeScheduleResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        message: 'getCompositeScheduleResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 getCompositeScheduleResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(getCompositeScheduleResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\get-local-list-version.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\n        res.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n        res.send Status(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"getCompositeScheduleResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        message: 'getLocalListVersionResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 getLocalListVersionResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(getLocalListVersionResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\reserve-now.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "connectorId: \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430,",
                                    React.createElement("br", null),
                                    "expiryDate: \u0434\u0430\u0442\u0430 \u0438\u0441\u0442\u0435\u0447\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "idTag \u2013\u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u0433\u0430 ",
                                    React.createElement("br", null),
                                    "parentIdTag(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0440\u043E\u0434\u0438\u0442\u0435\u043B\u044C\u0441\u043A\u0438\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u0442\u0435\u0433",
                                    React.createElement("br", null),
                                    "reservationId \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\n        connectorId: number\n        expiryDate: string\n        idTag: string\n        parentIdTag?: string\n        reservationId: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\n        res.send('Error')"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n        res.send Status(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"reserveNowResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        message: 'reserveNowResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 reserveNowResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(reserveNowResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\send-local-list.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 listVersion: \u043D\u043E\u043C\u0435\u0440 \u0432\u0435\u0440\u0441\u0438\u0438 ",
                                    React.createElement("br", null),
                                    "localAuthorizationList(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439)-\u043B\u043E\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u043B\u0438\u0441\u0442 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438 \u0434\u043B\u044F \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F rfid \u043A\u0430\u0440\u0442 \u043D\u0430 \u0437\u0430\u0440\u044F\u0434\u043D\u0443\u044E \u0441\u0442\u0430\u043D\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "updateType - \u0442\u0438\u043F \u0430\u043F\u0434\u0435\u0439\u0442\u0430 \u043B\u043E\u043A\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u043B\u0438\u0441\u0442\u0430",
                                    ' ')),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nlistVersion: number<br />\nlocalAuthorizationList?: OCPP160.datatypes.AuthorizationDataType[]<br />\nupdateType: OCPP160.datatypes.UpdateTypeEnumType"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.send Status(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"sendLocalListResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'sendLocalListResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 sendLocalListResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(sendLocalListResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\set-charging-profile.ts")))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441\u044B \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0435:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 ",
                                    React.createElement("br", null),
                                    "\u0421onnectionId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "chargingProfileId- \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u043F\u0440\u043E\u0444\u0438\u043B\u044F \u0437\u0430\u0440\u044F\u0434\u043A\u0438",
                                    React.createElement("br", null),
                                    "transactionId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0442\u0440\u0430\u043D\u0437\u0430\u043A\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "stackLevel - \u0443\u0440\u043E\u0432\u0435\u043D\u044C \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u0438\u044F \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F \u0432 \u0441\u0442\u0435\u043A\u0435 \u0438\u0435\u0440\u0430\u0440\u0445\u0438\u0438 \u0437\u0430\u0440\u044F\u0434\u043D\u044B\u0445 \u043F\u0440\u043E\u0444\u0438\u043B\u0435\u0439.\u043A\u0430",
                                    React.createElement("br", null),
                                    "chargingProfilePurpose- \u0426\u0435\u043B\u044C \u0437\u0430\u0440\u044F\u0434\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0444\u0438\u043B\u044F (\u043F\u0440\u0438 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u0438 \u0431\u0430\u043B\u0430\u043D\u0441\u0438\u0440\u043E\u0432\u043A\u0438 \u043D\u0430\u0433\u0440\u0443\u0437\u043A\u0438)",
                                    React.createElement("br", null),
                                    "chargingProfileKind: - \u0442\u0438\u043F \u043F\u0440\u043E\u0444\u0438\u043B\u044F \u0437\u0430\u0440\u044F\u0434\u043A\u0438",
                                    React.createElement("br", null),
                                    "recurrencyKind(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - ",
                                    React.createElement("br", null),
                                    "validFrom(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0439 \u043E\u0442",
                                    React.createElement("br", null),
                                    "validTo(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0434\u043E",
                                    React.createElement("br", null),
                                    "duration(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C",
                                    React.createElement("br", null),
                                    "startSchedule(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0441\u0442\u0430\u0440\u0442 \u0440\u0430\u0441\u043F\u0438\u0441\u0430\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "chargingRateUnit: \u0432\u0438\u0434 \u0435\u0434\u0438\u043D\u0438\u0446\u044B \u0438\u0437\u043C\u0435\u0440\u0435\u043D\u0438\u044F \u0441\u043A\u043E\u0440\u043E\u0441\u0442\u0438 \u0437\u0430\u0440\u044F\u0434\u043A\u0438",
                                    React.createElement("br", null),
                                    "chargingSchedulePeriod - \u043F\u0435\u0440\u0438\u043E\u0434 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0433\u0440\u0430\u0444\u0438\u043A\u0430 \u0437\u0430\u0440\u044F\u0434\u043A\u0438",
                                    React.createElement("br", null),
                                    "startPeriod \u2013 \u043D\u0430\u0447\u0430\u043B\u043E \u043F\u0435\u0440\u0438\u043E\u0434\u0430",
                                    React.createElement("br", null),
                                    "limit \u2013 \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0435",
                                    React.createElement("br", null),
                                    "numberPhases(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0447\u0438\u0441\u043B\u043E \u0444\u0430\u0437",
                                    React.createElement("br", null),
                                    "minChargingRate(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u043C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0441\u043A\u043E\u0440\u043E\u0441\u0442\u044C \u0437\u0430\u0440\u044F\u0434\u043A\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nconnectorId: number<br />\ncsChargingProfiles: {<br />\nchargingProfileId: number<br />\ntransactionId?: number<br />\nstackLevel: number<br />\nchargingProfilePurpose: OCPP160.datatypes.ChargingProfilePurposeTypeEnumType<br />\nchargingProfileKind: OCPP160.datatypes.ChargingProfileKindTypeEnumType<br />\nrecurrencyKind?: OCPP160.datatypes.RecurrencyKindTypeEnumType<br />\nvalidFrom?: string<br />\nvalidTo?: string<br />\nchargingSchedule: {<br />\nduration?: number<br />\nstartSchedule?: string<br />\nchargingRateUnit: OCPP160.datatypes.ChargingRateUnitTypeEnumType<br />\nchargingSchedulePeriod: {<br />\nstartPeriod: number<br />\nlimit: number<br />\nnumberPhases?: number<br />\n}[]<br />\nminChargingRate?: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"404\". ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.send Status(404)}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"setChargingProfileResponse\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'setChargingProfileResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 setChargingProfileResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(setChargingProfileResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\trigger-message.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "requestedMessage \u2013 \u0442\u0438\u043F \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "connectorId(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u044F")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nrequestedMessage: OCPP160.datatypes.MessageTriggerEnumType<br />\nconnectorId?: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 \"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"triggerMessageResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'triggerMessageResponse rejected',\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 triggerMessageResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(triggerMessageResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp1.6\\update-firmware.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421",
                                    React.createElement("br", null),
                                    "location - \u0440\u0430\u0441\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u0435",
                                    React.createElement("br", null),
                                    "retries(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435",
                                    React.createElement("br", null),
                                    "retrieveDate \u2013 \u0434\u0430\u0442\u0430 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "retryInterval(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) - \u0438\u043D\u0442\u0435\u0440\u0432\u0430\u043B \u043F\u043E\u043F\u044B\u0442\u043E\u043A")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nlocation: string\nretries?: number\nretrieveDate: string\nretryInterval?: number"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \"Websocket connection not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u0442\u0441\u044F \u043E\u0448\u0438\u0431\u043A\u0430 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0430 \"404\". ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\nres.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"updateFirmwareResponse rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'updateFirmwareResponse rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 updateFirmwareResponse")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(updateFirmwareResponse)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "src\\routes\\ocpp2.0\\statusTransaction.ts")),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "transactionId \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0442\u0440\u0430\u043D\u0437\u0430\u043A\u0446\u0438\u0438")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "transactionId: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Transaction not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Transaction not found', { id: transactionId })\nres.sendStatus(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\" \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"ChargingStation not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { id: chargingStationId })\nres.sendStatus(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C transactionUpdated")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\ntransaction: transactionUpdated})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Maybe Transaction is Stopped\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'Maybe Transaction is Stopped'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp2.0\\get-variables.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 getVariableData \u2013 \u0442\u0438\u043F \u043F\u043E\u043B\u0443\u0447\u0430\u0435\u043C\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\ngetVariableData: OCPP201.datatypes.GetVariableDataType[]"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Websocket connection not found\" \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0421\u043E\u0445\u0440\u0430\u043D\u044F\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Transaction not found\" \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Transaction not found', { id: transactionId })\nres.sendStatus(400)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \"getVariableResult\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\ngetVariableResult: getVariablesResponse.getVariableResult})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"No getVariableResult\" ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'No getVariableResult',})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\create-station.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "Key - \u0438\u043D\u0434\u0438\u0432\u0438\u0434\u0443\u0430\u043B\u044C\u043D\u044B\u0439 \u043A\u043B\u044E\u0447",
                                    React.createElement("br", null),
                                    "id(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043D\u043E\u043C\u0435\u0440",
                                    React.createElement("br", null),
                                    "connectors \u2013 \u0442\u0438\u043F \u043A\u043E\u043D\u043D\u0435\u043A\u0442\u043E\u0440\u0430",
                                    React.createElement("br", null),
                                    "type(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0442\u0438\u043F \u0441\u0442\u0430\u043D\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "name \u2013 \u0438\u043C\u044F \u0441\u0442\u0430\u043D\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "serialNumber \u2013 \u0441\u0435\u0440\u0438\u0439\u043D\u044B\u0439 \u043D\u043E\u043C\u0435\u0440 \u0441\u0442\u0430\u043D\u0446\u0438\u0438",
                                    React.createElement("br", null),
                                    "ocppVersion \u2013 \u0432\u0435\u0440\u0441\u0438\u044F OCPP",
                                    React.createElement("br", null),
                                    "evses \u2013 \u0441\u0432\u043E\u0439\u0441\u0442\u0432\u043E EVSE")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "[key: string]: string | number | null | object\nid?: number\nconnectors: ConnectorProp[]\ntype?: ConnectorTypeEnum\nname: string\nserialNumber: string\nocppVersion: OcppSupportedEnum\nevses: EvseProp[]"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Parameters to create station not provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Parameters to create station not provided!'\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: Serial number must be provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during station create: Serial number must be provided!'\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: Station name must be provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during station create: Station name must be provided!'\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: OCPP version must be provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during station create: OCPP version must be provided!'\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: unknown OCPP version provided! Allowed versions:\"+ \u0434\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u0430\u044F \u0432\u0435\u0440\u0441\u0438\u044F \u041E\u0421\u0420\u0420",
                                    ' ')),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({error}: true,\n                  code: 400,\n                  message: \"Error during station create: unknown OCPP version provided! Allowed versions: {Object.values(OcppSupportedEnum).join(', ')}\""))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C evseCheckResult")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(evseCheckResult)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 &quot;Charging station with serial number {serialNumber}",
                                    "already exists!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: Charging station with serial number {params.serialNumber} already exists!"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during creating charging station!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during creating charging station!'\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Create-station failed!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Create-station failed!',\n})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C createdStation")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(createdStation)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src/routes/ocpp1.6/data-transfer.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u043F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421 ",
                                    React.createElement("br", null),
                                    "vendorId - \u0438\u043C\u044F \u0432\u0435\u043D\u0434\u043E\u0440\u0430, ",
                                    React.createElement("br", null),
                                    "messageId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F",
                                    React.createElement("br", null),
                                    "data - \u0434\u0430\u043D\u043D\u044B\u0435")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nvendorId: string\nmessageId: string\ndata: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"Error\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('Websocket connection not found', { chargingStationId })\nres.send('Error')}"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u043C \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441\"404\".")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "logger.error('ChargingStation not found', { chargingStationId })\n res.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u043E\u0448\u0438\u0431\u043A\u0443 \"DataTransferRequest rejected\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\nmessage: 'DataTransferRequest rejected'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\edit-station.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421,",
                                    React.createElement("br", null),
                                    "ocppVersion (\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439) \u2013 \u0432\u0435\u0440\u0441\u0438\u044F OCPP,",
                                    React.createElement("br", null),
                                    "evses \u2013 \u0442\u0438\u043F EVSE")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string\nparams: {\n[key: string]: string | number | null | object\nocppVersion</a>?: OcppSupportedEnum\nevses?: EvseProp[]"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Parameters to create station not provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Parameters to create station not provided!'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: Serial number must be provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during station create: Serial number must be provided!'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: Station name must be provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during station create: Station name must be provided!' })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during station create: OCPP version must be provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during station create: OCPP version must be provided!' })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \" Error during station create: unknown OCPP version provided! Allowed versions: $(OcppSupportedEnum)")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({error}: true,\n                      code: 400,\n                      message: \"Error during station create: unknown OCPP version provided! Allowed versions:Object.values(OcppSupportedEnum).join(', ')\""))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C evseCheckResult")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(evseCheckResult)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    ' Возвращаем статус 400 и ошибку &quot;Charging station with serial number {serialNumber}',
                                    "already exists!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: Charging station with serial number {params.serialNumber} already exists!"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Error during creating charging station!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'Error during creating charging station!' })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Create-station failed!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        code: 400,\n        message: 'Create-station failed!' })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C createdStation")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(createdStation)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\get-station.ts"))),
                            React.createElement("td", { width: "380", align: "left" })),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "chargingStationId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0417\u0421")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "chargingStationId: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"chargingStationId to get station not provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\n        error: true,\n        code: 400,\n        message: 'chargingStationId to get station not provided!'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C chargingStation \u0438\u043B\u0438 \u043E\u0448\u0438\u0431\u043A\u0443 \"Get-station failed!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\n        chargingStation || {\n        error: true,\n        message: 'Get-station failed!'})"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "right" },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "src\\routes\\ocpp\\get-transaction.ts")))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null,
                                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441 \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0439:",
                                    React.createElement("br", null),
                                    "transactionId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0442\u0440\u0430\u043D\u0437\u0430\u043A\u0446\u0438\u0438(\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439)",
                                    React.createElement("br", null),
                                    "remoteStartId - \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0443\u0434\u0430\u043B\u0451\u043D\u043D\u043E\u0433\u043E \u0437\u0430\u043F\u0443\u0441\u043A\u0430 (\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439)")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "transactionId?: string\nremoteStartId?: string"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 400 \u0438 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \"transactionId or remoteStartId to get transaction not provided!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send({\nerror: true,\ncode: 400,\nmessage: 'transactionId or remoteStartId to get transaction not provided!' })"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0435\u043C \u0441\u0442\u0430\u0442\u0443\u0441 404 ")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, " res.sendStatus(404)"))),
                        React.createElement("tr", null,
                            React.createElement("td", { width: "600", align: "left" },
                                React.createElement("p", null, "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0435\u043C meterValues \u0438\u043B\u0438 \u043E\u0448\u0438\u0431\u043A\u0430 \"Get-transaction failed!\"")),
                            React.createElement("td", { width: "380", align: "left" },
                                React.createElement("pre", { className: "prettyprint" }, "res.send(\n{ ...transaction.toJSON(),\nmeterValues\n} || {\nerror: true,\nmessage: 'Get-transaction failed!', } )})"))))))));
    };
    return Api;
}(React.Component));
export default Api;
