var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var Instructions = /** @class */ (function (_super) {
    __extends(Instructions, _super);
    function Instructions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Instructions.prototype.render = function () {
        return (React.createElement("main", null,
            React.createElement("div", { className: "container pb-100 pt-100" },
                React.createElement("div", { className: "m-auto col-xl-8 " },
                    React.createElement("h1", { className: "section-title text-center pb-5" }, "\u0418\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F \u043F\u043E \u0443\u0441\u0442\u0430\u043D\u043E\u0432\u043A\u0435 \u0441\u0435\u0440\u0432\u0438\u0441\u0430 CSMS"),
                    React.createElement("h3", { className: "section-title text-center pb-5" }, "\u041F\u043E\u0434\u0433\u043E\u0442\u043E\u0432\u043A\u0430 \u043E\u043A\u0440\u0443\u0436\u0435\u043D\u0438\u044F:"),
                    React.createElement("div", { className: "text-body" },
                        React.createElement("p", null, "1) \u0423\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u043D\u0430 \u043A\u043E\u043C\u043F\u044C\u044E\u0442\u0435\u0440 node.js \u0432\u0435\u0440\u0441\u0438\u0438 16.20.\u0425\u0425. \u0421\u0441\u044B\u043B\u043A\u0430 \u0434\u043B\u044F c\u043A\u0430\u0447\u0438\u0432\u0430\u043D\u0438\u044F:"),
                        React.createElement("a", { style: { color: '#007bff', textDecoration: 'underline' }, href: "https://nodejs.org/en/blog/release/v16.20.2", rel: "noreferrer", target: "_blank" }, "https://nodejs.org/en/blog/release/v16.20.2"),
                        React.createElement("p", null, "2) \u0423\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u0435 \u043C\u0435\u043D\u0435\u0434\u0436\u0435\u0440 \u043F\u0430\u043A\u0435\u0442\u043E\u0432 yarn \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0432 \u0432 \u0442\u0435\u0440\u043C\u0438\u043D\u0430\u043B\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u0443: "),
                        React.createElement("p", null,
                            ' ',
                            React.createElement("b", null, "npm install --global yarn")),
                        React.createElement("p", null, "3) \u0423\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C Docker Desktop \u043D\u0430 \u043A\u043E\u043C\u043F\u044C\u044E\u0442\u0435\u0440. \u0421\u0441\u044B\u043B\u043A\u0430 \u0434\u043B\u044F \u0443\u043A\u0430\u0447\u0438\u0432\u0430\u043D\u0438\u044F: "),
                        React.createElement("a", { style: { color: '#007bff', textDecoration: 'underline' }, href: "https://www.docker.com/products/docker-desktop/", rel: "noreferrer", target: "_blank" }, "https://www.docker.com/products/docker-desktop/"),
                        React.createElement("p", null, "4) \u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C Docker Desktop"),
                        React.createElement("p", null,
                            "5) \u0421\u043A\u043E\u043F\u0438\u0440\u0443\u0439\u0442\u0435 \u0444\u0430\u0439\u043B ",
                            React.createElement("b", null, ".env.example"),
                            " \u0432 ",
                            React.createElement("b", null, ".env")),
                        React.createElement("p", null,
                            "6) \u041F\u0440\u0438 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E\u0441\u0442\u0438, \u0432 \u0444\u0430\u0439\u043B\u0435 ",
                            React.createElement("b", null, ".env"),
                            " \u0437\u0430\u043C\u0435\u043D\u0438\u0442\u0435 \u043B\u043E\u0433\u0438\u043D/\u043F\u0430\u0440\u043E\u043B\u044C \u0434\u043B\u044F \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u043A API*:",
                            ' ',
                            React.createElement("b", null, "BASIC_AUTH_USERNAME"),
                            " \u0438 ",
                            React.createElement("b", null, "BASIC_AUTH_PASSWORD")),
                        React.createElement("p", null, " *\u0434\u043B\u044F \u0446\u0435\u043B\u0435\u0439 \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u043E\u0441\u0442\u0438, \u0432 \u0441\u0435\u0440\u0432\u0438\u0441\u0435 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u0442\u0441\u044F basic-auth \u0441\u0445\u0435\u043C\u0430 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438 REST API \u0437\u0430\u043F\u0440\u043E\u0441\u043E\u0432"),
                        React.createElement("h3", { className: "section-title text-center pb-5" }, "\u0423\u0441\u0442\u0430\u043D\u043E\u0432\u043A\u0430:"),
                        React.createElement("p", null, " 1) \u0423\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u0435 \u0437\u0430\u0432\u0438\u0441\u0438\u043C\u043E\u0441\u0442\u0438 \u043F\u0440\u043E\u0435\u043A\u0442\u0430 \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0432 \u0432 \u0442\u0435\u0440\u043C\u0438\u043D\u0430\u043B\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u0443:"),
                        React.createElement("p", null,
                            ' ',
                            React.createElement("b", null, "yarn install")),
                        React.createElement("p", null, " 2) \u0423\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u0435 \u0438 \u0437\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u0435 docker \u043A\u043E\u043D\u0442\u0435\u0439\u043D\u0435\u0440 \u0441 \u0411\u0414 \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0432 \u0432 \u0442\u0435\u0440\u043C\u0438\u043D\u0430\u043B\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u0443:"),
                        React.createElement("p", null,
                            React.createElement("b", null, "yarn docker-run-db")),
                        React.createElement("p", null, " 3) \u041F\u043E\u0434\u0433\u043E\u0442\u043E\u0432\u044C\u0442\u0435 \u0411\u0414 \u043A \u0440\u0430\u0431\u043E\u0442\u0435 \u0441 \u0441\u0435\u0440\u0432\u0438\u0441\u043E\u043C \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0432 \u0432 \u0442\u0435\u0440\u043C\u0438\u043D\u0430\u043B\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u0443: "),
                        React.createElement("p", null,
                            React.createElement("b", null, "yarn docker-setup-db")),
                        React.createElement("h3", { className: "section-title text-center pb-5" }, "\u0417\u0430\u043F\u0443\u0441\u043A:"),
                        React.createElement("p", null, "\u0414\u043B\u044F \u0437\u0430\u043F\u0443\u0441\u043A\u0430 \u0441\u0435\u0440\u0432\u0438\u0441\u0430 \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u0432 \u0442\u0435\u0440\u043C\u0438\u043D\u0430\u043B\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u0443: "),
                        React.createElement("p", null,
                            React.createElement("b", null, "yarn start")))))));
    };
    return Instructions;
}(React.Component));
export default Instructions;
