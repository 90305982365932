var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { hydrate, render } from 'react-dom';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/bgset/ls.bgset.js';
import 'lazysizes';
import App from './app/App';
import routes from './routes';
var Main = function () { return (React.createElement(BrowserRouter, null,
    React.createElement(App, null,
        React.createElement(Switch, null,
            React.createElement(Redirect, { from: "/offerta/", to: "/oferta/" }),
            routes.map(function (route, i) { return (React.createElement(Route, { key: i, path: route.path, exact: route.exact, render: function (props) {
                    var ComponentView = route.component;
                    return React.createElement(ComponentView, __assign({}, props, { route: route }));
                } })); }))))); };
var renderMethod = module.hot ? render : hydrate;
renderMethod(React.createElement(Main, null), document.getElementById('app'));
